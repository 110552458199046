import { useAppState } from '../../app/contexts/AppStateContext';
import settings from '../../app/settings';
import { Map } from '../../components/Map/Map';
import { TMapTypeSelectorType } from '../../components/MapLayerSelector/MapTypeSelector';
import { MapTypeLightning } from '../../components/MapTypeLightning/MapTypeLightning';
import { MapTypeRadar } from '../../components/MapTypeRadar/MapTypeRadar';
import { MapTypeTemperature } from '../../components/MapTypeTemperature/MapTypeTemperature';
import { MapTypeWeather } from '../../components/MapTypeWeather/MapTypeWeather';
import { MapTypeWind } from '../../components/MapTypeWind/MapTypeWind';
import { PageRoot } from '../../components/PageRoot/PageRoot';
import './StandaloneMapPage.scss';

export default function StandaloneMapPage() {
  const { currentPage } = useAppState();
  const { subpageId } = currentPage.details.params;
  const { embedded } = currentPage.details.query;

  return (
    <PageRoot className="standalone-map-page" isContainer={true}>
      <Map
        bounds={settings.map.bounds[subpageId as 'wind' | 'search' | 'radar' | 'lightning' | 'temperature']}
        cursor={'arrow'}
        mapTypeSelector={
          subpageId !== 'search'
            ? {
                currentMapType: subpageId as TMapTypeSelectorType,
                mapTypes: {
                  weather: true,
                  radar: true,
                  wind: true,
                  lightning: true,
                  temperature: true
                }
              }
            : undefined
        }
        shouldShowLegend={embedded === false}
        shouldShowFullScreenButton={embedded === false}
        shouldShowZoomToolbar={embedded === false}
        shouldShowInfoButton={embedded === false}
        shouldShowMapTypeSelector={embedded === false}
        render={({ map, setAriaLabel, styles }) => {
          return (
            <>
              {subpageId === 'weather' && (
                <MapTypeWeather map={map} style={styles.default} setAriaLabel={setAriaLabel} />
              )}
              {subpageId === 'radar' && (
                <MapTypeRadar map={map} style={styles.defaultWithName} setAriaLabel={setAriaLabel} />
              )}
              {subpageId === 'wind' && <MapTypeWind map={map} style={styles.heatmap} setAriaLabel={setAriaLabel} />}
              {subpageId === 'lightning' && (
                <MapTypeLightning map={map} style={styles.defaultWithName} setAriaLabel={setAriaLabel} />
              )}
              {subpageId === 'temperature' && (
                <MapTypeTemperature map={map} style={styles.heatmap} setAriaLabel={setAriaLabel} />
              )}
            </>
          );
        }}
      />
    </PageRoot>
  );
}
